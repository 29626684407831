export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155')
];

export const server_loads = [0];

export const dictionary = {
		"/": [11],
		"/actions/karma": [13],
		"/auth": [14],
		"/bloomerinvite": [15],
		"/bloomers-without-borders": [16],
		"/bounty": [17],
		"/bounty/claim/[claimId]": [18],
		"/chat": [19],
		"/chat/all": [20],
		"/chat/creatives": [21],
		"/chat/mybloom": [22],
		"/chat/water": [23],
		"/chat/water/aridLandscapes": [24],
		"/communityposts": [25],
		"/create/[type]": [26],
		"/donate": [27],
		"/edit/[slug]": [28],
		"/engage/communityCalls": [29],
		"/engage/contributors": [30],
		"/engage/donate": [31],
		"/engage/localBlooms": [32],
		"/engage/privacy": [33],
		"/engage/projects": [34],
		"/grants/distribution": [35,[2]],
		"/grants/roles": [36,[2]],
		"/grants/verify": [37,[2]],
		"/grants/verify/[slug]": [38,[2]],
		"/home": [39],
		"/joinABloom": [54],
		"/join": [40],
		"/join/localmember": [41],
		"/join/localmember/login": [42],
		"/join/old/helper": [43],
		"/join/old/schedule": [44],
		"/join/old/you": [45],
		"/join/register": [46],
		"/join/register/admin": [47],
		"/join/register/bloomerswithoutborders": [48],
		"/join/register/bloomerswithoutborders/apply": [49],
		"/join/register/joinlocal": [50],
		"/join/register/joinlocal/apply": [51],
		"/join/register/orientation": [52],
		"/join/register/startlocalhub": [53],
		"/learn/faq": [55,[3]],
		"/learn/faq/actionPosts": [56,[3]],
		"/learn/faq/businessAgreements": [57,[3]],
		"/learn/faq/localmembers": [58,[3]],
		"/learn/faq/membership": [59,[3]],
		"/learn/faq/wallet-across-apps": [60,[3]],
		"/learn/faq/withdrawing-funds": [61,[3]],
		"/learn/syllabus": [62,[4]],
		"/learn/syllabus/art": [63,[4]],
		"/learn/syllabus/economy": [64,[4]],
		"/learn/syllabus/intro": [65,[4]],
		"/learn/syllabus/nature": [66,[4]],
		"/learn/syllabus/tech": [67,[4]],
		"/learn/templates": [68,[5]],
		"/learn/templates/beautifyCommons": [69,[5]],
		"/learn/templates/bioregional-hackathon": [70,[5]],
		"/learn/templates/bloom-brand-kit": [71,[5]],
		"/learn/templates/communitygarden": [72,[5]],
		"/learn/templates/conflictResolution": [73,[5]],
		"/learn/templates/createActionTemplate": [74,[5]],
		"/learn/templates/cropswap": [75,[5]],
		"/learn/templates/csaa": [76,[5]],
		"/learn/templates/emailList": [77,[5]],
		"/learn/templates/eventFollowup": [78,[5]],
		"/learn/templates/eventReminder": [79,[5]],
		"/learn/templates/farmlandcommons": [80,[5]],
		"/learn/templates/kickoff": [81,[5]],
		"/learn/templates/longevity": [82,[5]],
		"/learn/templates/mediaTeam": [83,[5]],
		"/learn/templates/meditations": [84,[5]],
		"/learn/templates/mission": [85,[5]],
		"/learn/templates/proposalTemplate": [86,[5]],
		"/learn/templates/roles": [87,[5]],
		"/learn/templates/team-email": [88,[5]],
		"/learn/templates/thinktank": [89,[5]],
		"/learn/templates/urbanfarm": [90,[5]],
		"/learn/templates/vetting": [91,[5]],
		"/learn/whitepaper": [92],
		"/learn/whitepaper/business": [93],
		"/learn/whitepaper/economicprotocol": [94],
		"/learn/whitepaper/governance": [95],
		"/learn/whitepaper/governance/research": [96],
		"/learn/wiki": [97,[6]],
		"/learn/wiki/agroforestry": [98,[6]],
		"/learn/wiki/bioregional-governance": [99,[6]],
		"/learn/wiki/bioregionalism": [100,[6]],
		"/learn/wiki/cobb-building": [101,[6]],
		"/learn/wiki/community-land-trusts": [102,[6]],
		"/learn/wiki/companion-planting": [103,[6]],
		"/learn/wiki/earthships": [104,[6]],
		"/learn/wiki/ecosystem-restoration": [105,[6]],
		"/learn/wiki/federated-cooperatives": [106,[6]],
		"/learn/wiki/food-forests": [107,[6]],
		"/learn/wiki/gift-economy": [108,[6]],
		"/learn/wiki/hempcrete-building": [109,[6]],
		"/learn/wiki/indigenous-leadership": [110,[6]],
		"/learn/wiki/living-roofs": [111,[6]],
		"/learn/wiki/local-currencies": [112,[6]],
		"/learn/wiki/native-species": [113,[6]],
		"/learn/wiki/natural-building": [114,[6]],
		"/learn/wiki/participatory-finance": [115,[6]],
		"/learn/wiki/permaculture": [116,[6]],
		"/learn/wiki/refi": [117,[6]],
		"/learn/wiki/regenerative-economics": [118,[6]],
		"/learn/wiki/restorative-justice": [119,[6]],
		"/learn/wiki/transformative-justice": [120,[6]],
		"/learn/wiki/web3-and-cryptocurrencies": [121,[6]],
		"/localpage/new": [123],
		"/localpage/[location]": [122],
		"/marketplace": [124],
		"/member": [125],
		"/member/begin": [128],
		"/member/blogs": [129],
		"/member/bounty": [130],
		"/member/bounty/scratch": [131],
		"/member/dms": [132],
		"/member/dms/[slug]": [133],
		"/member/flo/offers": [134,[9]],
		"/member/flo/offers/create": [135,[9]],
		"/member/flo/offers/myoffers": [136,[9]],
		"/member/flo/offers/mypurchases": [137,[9]],
		"/member/flo/offers/rate/[id]": [138,[9]],
		"/member/localactionrewards": [139],
		"/member/localactionrewards/impactreport": [140],
		"/member/localactionrewards/localmembers": [141],
		"/member/nobloomyet": [142],
		"/member/notifications": [143],
		"/member/viewGifts": [144],
		"/member/viewGifts/localbloom": [145],
		"/member/viewSkills": [146],
		"/member/voting/features": [147],
		"/member/[username]": [126,[7]],
		"/member/[username]/profile": [127,[7,8]],
		"/profile": [148,[10]],
		"/startLocal": [149],
		"/team": [150],
		"/testroute": [151],
		"/view": [152],
		"/view/[slug]": [153],
		"/vision": [154],
		"/wallet": [155],
		"/[location]": [12]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
	
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';